export default function AnalyticsDashboard() {
  return (
    <div style={{ padding: '1rem', height: 'calc(100vh - 70px)', width: '100%'}}>
      <iframe
        title="Unnoti Analytics Dashboard"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiZDg1NDFlNzctYjQ4NC00MzMyLWFmZmQtODFkYzNmZDc0YzFkIiwidCI6ImIzNTZkZDk0LTM0YmEtNDZkOS05MWYzLWY1NzRhYjZhMjQ3NCIsImMiOjEwfQ%3D%3D"
        frameborder="0"
        allowFullScreen="true"
      />
    </div>
  )
}
